// 忘记密码
// mxy
<template>
  <div class="main">
    <a-form
      class="user-layout-login"
      :rules="rules"
      ref="formRef"
      :model="userPhone"
    >
      <a-tabs :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }">
        <a-tab-pane key="tab1" tab="忘记密码">
          <a-form-item required name="value" :wrapper-col="{ span: 24 }">
            <a-input
              size="large"
              :maxLength="11"
              type="text"
              v-model:value="userPhone.value"
              placeholder="手机号码"
            >
              <template #prefix>
                <MobileOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-row :gutter="16">
            <a-col class="gutter-row" :span="16">
              <a-form-item
                required
                name="checkCode"
                :wrapper-col="{ span: 24 }"
              >
                <a-input
                  size="large"
                  type="text"
                  v-model:value="userPhone.checkCode"
                  :maxlength="6"
                  placeholder="验证码"
                >
                  <template #prefix>
                    <MailOutlined style="color: rgba(0, 0, 0, 0.25)" />
                  </template>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :span="8">
              <a-button
                class="getCaptcha"
                v-if="userPhone.buttonVisable"
                @click="GetCheckCode"
              >
                获取验证码
              </a-button>
              <a-button class="getCaptcha" v-if="!userPhone.buttonVisable"
                >{{ userPhone.countDown }} 秒后重发</a-button
              >
            </a-col>
          </a-row>
          <a-form-item required name="password" :wrapper-col="{ span: 24 }">
            <a-input-password
              v-model:value="userPhone.password"
              type="password"
              :maxlength="16"
              size="large"
              visibilityToggle
              placeholder="新密码:6-16位,数字,大小写字母,非字符串"
            >
              <template #prefix>
                <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input-password>
          </a-form-item>
          <a-form-item
            required
            name="confirmPassword"
            :wrapper-col="{ span: 24 }"
          >
            <a-input-password
              v-model:value="userPhone.confirmPassword"
              type="password"
              :maxlength="16"
              size="large"
              visibilityToggle
              placeholder="确认密码"
            >
              <template #prefix>
                <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input-password>
          </a-form-item>
        </a-tab-pane>
      </a-tabs>

      <a-form-item
        class="login-button"
        style="margin-top: 24px"
        :wrapper-col="{ span: 24 }"
      >
        <a-button
          type="primary"
          html-type="submit"
          size="large"
          class="login-button"
          @click="registered"
          :loading="state.loginBtn"
          :disabled="state.loginBtn"
        >
          确认
        </a-button>
      </a-form-item>

      <a-form-item class="bottom" :wrapper-col="{ span: 24 }">
        <span>已有账号？</span>
        <router-link to="/account/login">
          <span class="font-size">返回登录</span>
        </router-link>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import { ref, reactive } from 'vue'
// import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
// import { getProfileDetail } from '@/api/IdentityAPI'
import { MobileOutlined, MailOutlined, LockOutlined } from '@ant-design/icons-vue'
// import { notification, message } from 'ant-design-vue'
// import { login, getSmsCaptcha } from '@/api/account/AccountAPI'

import api from '@/api/API'
export default {
  name: 'account-register',
  components: {
    MobileOutlined,
    MailOutlined,
    LockOutlined,
  },
  data () {
    return {
      userPhone: {
        edit: false,
        value: '',
        checkCode: '',
        buttonVisable: true,
        countDown: null,
        timer: null,
        password: '',
        confirmPassword: '',
      }, // 账户手机
      state: {
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false,
      },
      rules: {
        value: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur',
          },
          {
            len: 11,
            message: '请输入正确的11位手机号码',
            trigger: 'blur',
          },
        ],
        checkCode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
          {
            len: 6,
            message: '请输入正确的6位验证码',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: '请再次输入密码',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
    }
  },
  methods: {
    // 获取验证码
    GetCheckCode () {
      const phones = {
        phone: this.userPhone.value,
      }
      if (this.userPhone.value === '') {
        this.$message.error('请先填写手机号')
        return
      }
      if (this.userPhone.value.length !== 11) {
        this.$message.error('请填写正确的11位手机号')
        return
      }
      api
        .post('/api/app/account/send-verifycation-code', phones)
        .then(({ data }) => {
          this.spinning = false
          this.countDown()
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取验证码失败' + err)
          console.log(err)
        })
    },
    // 倒计时
    countDown () {
      const TIME_COUNT = 60
      this.userPhone.countDown = TIME_COUNT
      this.userPhone.buttonVisable = false
      this.userPhone.timer = setInterval(() => {
        if (this.userPhone.countDown > 0 && this.userPhone.countDown <= TIME_COUNT) {
          this.userPhone.countDown--
        } else {
          this.userPhone.buttonVisable = true
          clearInterval(this.userPhone.timer)
          this.userPhone.timer = null
        }
      }, 1000)
    },
    registered () {
      this.$refs.formRef
        .validate()
        .then(() => {
          if (this.userPhone.password !== this.userPhone.confirmPassword) {
            this.$message.error('两次输入密码不一致，请重新输入')
            return
          }
          this.state.loginBtn = true
          const obj = {
            phoneNumber: this.userPhone.value,
            email: '',
            userName: this.userPhone.value,
            password: this.userPhone.password,
            checkCode: this.userPhone.checkCode,
          }
          api
            .post('/api/app/account/find-password-by-phone-number', obj)
            .then(({ data }) => {
              this.spinning = false
              this.countDown()
              this.state.loginBtn = false
              this.$message.success('已重新设置密码！')
              setTimeout(() => {
                window.location.href = '/account/login'
              }, 200)
            }).catch(err => {
              this.spinning = false
              this.state.loginBtn = false
              console.log(err)
            })
        }).catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  padding: 60px;

  .ant-form-item-control-wrapper {
    width: 100% !important;
  }
  .user-layout-login {
    label {
      font-size: 14px;
    }
    .getCaptcha {
      display: block;
      width: 100%;
      height: 40px;
    }

    .forge-password {
      font-size: 14px;
    }

    button.login-button {
      padding: 0 15px;
      font-size: 16px;
      height: 40px;
      width: 100%;
      background-color: #134ccf;
      color: #fff;
    }

    .user-login-other {
      text-align: left;
      margin-top: 24px;
      line-height: 22px;

      .item-icon {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.2);
        margin-left: 16px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #1890ff;
        }
      }

      .register {
        float: right;
      }
    }
  }
  .btnGetCheckCode {
    border: 0px;
    background-color: #fff;
    color: blue;
  }
  .bottom {
    margin-top: -20px;
    text-align: center;
    font-size: 12px;
  }
}
::v-deep(.ant-tabs-tab:hover) {
  color: #134ccf;
}
::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
  color: #134ccf;
}
::v-deep(.ant-tabs-nav .ant-tabs-tab:active) {
  color: #134ccf;
}
::v-deep(.ant-tabs-ink-bar) {
  background-color: #134ccf;
}
::v-deep(.ant-btn:hover, .ant-btn:focus) {
  border-color: #134ccf;
  color: #134ccf;
  opacity: 0.7;
}
::v-deep(.login-button.ant-btn:hover, .ant-btn:focus) {
  border-color: #134ccf;
  color: #fff;
}
::v-deep(.ant-input-affix-wrapper:hover, .ant-input-affix-wrapper:focus) {
  border-color: #134ccf;
}
::v-deep(.ant-input-affix-wrapper-focused) {
  border-color: #134ccf;
  box-shadow: none;
}
</style>
